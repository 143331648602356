<template lang='pug'>
div.form__wrapper
  img.logo(src='../assets/logo.png')
  h1 You're all set!
  p Your proton.ai account has been created with the provided credentials.

  a(:href='loginUrl')
    el-button(type='text') Log in now
</template>

<script>
import { buildRedirectUrl } from '../utils'

export default {
  name: 'ConfirmAccount',
  computed: {
    loginUrl () {
      const company = this.$route.query.company

      if (!company) {
        return '/'
      } else {
        return buildRedirectUrl(company)
      }
    }
  },
}
</script>

<style lang='scss'>
@import '../scss/common';
</style>